/* Estilos Base */
.home-page {
    font-family: 'Inter', sans-serif;
    color: #1a1a1a;
    overflow-x: hidden;
  }
  
  /* Seção Hero */
  .hero-section {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 5%;
    position: relative;
  }
  
  .hero-title {
    font-size: clamp(3rem, 8vw, 6rem);
    font-weight: 700;
    line-height: 1.1;
    margin-bottom: 2rem;
  }
  
  .highlight {
    color: #D84315;
  }
  
  .outline {
    -webkit-text-stroke: 1px #1a1a1a;
    color: transparent;
  }
  
  .hero-subtitle {
    font-size: 1.25rem;
    max-width: 600px;
    margin-bottom: 3rem;
  }
  
  .hero-cta {
    display: flex;
    gap: 1rem;
  }
  
  .cta-primary {
    background: #D84315;
    color: white;
    padding: 1rem 2rem;
    border-radius: 50px;
    text-decoration: none;
    font-weight: 500;
    transition: all 0.3s ease;
  }
  
  .cta-secondary {
    border: 1px solid #1a1a1a;
    padding: 1rem 2rem;
    border-radius: 50px;
    text-decoration: none;
    font-weight: 500;
    transition: all 0.3s ease;
  }
  
  .hero-scroll {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .scroll-line {
    height: 60px;
    width: 1px;
    background: #1a1a1a;
    margin-top: 1rem;
  }
  
  /* Seção Destaque */
  .feature-section {
    margin: 10rem 0;
  }
  
  .feature-video {
    position: relative;
    height: 80vh;
    overflow: hidden;
  }
  
  .feature-video video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .feature-overlay {
    position: absolute;
    bottom: 10%;
    left: 5%;
    color: white;
    max-width: 500px;
    background-color: rgba(0,0,0,0.6);
    padding: 2rem;
    border-radius: 8px;
  }
  
  /* Seção Serviços */
  .services-section {
    padding: 8rem 5%;
  }
  
  .section-title {
    font-size: clamp(2rem, 5vw, 3rem);
    margin-bottom: 5rem;
    text-align: center;
  }
  
  .services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
  }
  
  .service-card {
    padding: 2rem;
    border-radius: 8px;
    transition: all 0.3s ease;
  }
  
  .service-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 30px rgba(0,0,0,0.1);
  }
  
  .service-icon svg {
    width: 40px;
    height: 40px;
    fill: #EB5F2C;
    margin-bottom: 1.5rem;
  }
  
  /* Seção Processo */
  .process-section {
    background: #f8f8f8;
    padding: 8rem 5%;
  }
  
  .process-steps {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 3rem;
    margin-top: 4rem;
  }
  
  .process-step {
    position: relative;
    padding-top: 3rem;
  }
  
  .step-number {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 3rem;
    font-weight: 700;
    color: rgba(0,0,0,0.05);
  }
  
  /* Seção Clientes */
  .clients-section {
    padding: 8rem 5%;
  }
  
  .clients-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 3rem;
    margin-top: 5rem;
  }
  
  .client-logo {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    font-weight: 700;
  }
  
  /* Seção Contato */
  .contact-section {
    background: #1a1a1a;
    color: white;
    padding: 8rem 5%;
    text-align: center;
  }
  
  .contact-content h2 {
    font-size: clamp(2rem, 5vw, 3rem);
    margin-bottom: 1rem;
  }
  
  .contact-content p {
    margin-bottom: 3rem;
    font-size: 1.25rem;
    opacity: 0.8;
  }

  /* Texto escondido visualmente (acessível) */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}