/* Estilo Pentagram */
.pentagram-portfolio {
  padding: 40px;
  font-family: 'Helvetica Neue', sans-serif;
}

.pentagram-filters {
  text-align: right;
  margin-bottom: 30px;
}

.pentagram-filter {
  background: none;
  border: none;
  margin-left: 20px;
  font-size: 14px;
  color: #999;
  cursor: pointer;
  text-transform: capitalize;
  letter-spacing: 0.5px;
}

.pentagram-filter.active {
  color: #000;
  font-weight: 500;
}

/* Grid assimétrico */
.pentagram-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.pentagram-card {
  position: relative;
  overflow: hidden;
}

/* Classes para tamanhos diferentes (como no Pentagram) */
.pentagram-card.wide {
  grid-column: span 2;
}

.pentagram-card.tall {
  grid-row: span 2;
}

/* Estilo das imagens */
.pentagram-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.pentagram-card:hover img {
  transform: scale(1.03);
}

/* Texto (aparece apenas no hover) */
.pentagram-card .project-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  transform: translateY(100%);
  transition: transform 0.3s ease;
}

.pentagram-card:hover .project-info {
  transform: translateY(0);
}

.pentagram-description {
  font-size: 13px;
  line-height: 1.4;
  margin-top: 8px;
  opacity: 0.9;
}